<!-- =========================================================================================
    File Name: ApexCharts.vue
    Description: Apex Charts Demo
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="extra-component-chartist-demo">
        <p class="mb-4">A Vue.js component for ApexCharts. Read full documnetation <a href="https://apexcharts.com/docs/installation/" target="_blank" rel="nofollow">here</a></p>

        <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Line Chart" code-toggler>
                    <vue-apex-charts type="line" height="350" :options="apexChatData.lineChartSimple.chartOptions" :series="apexChatData.lineChartSimple.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.lineChartSimpleCode }}
                    </template>
                </vx-card>
            </div>

            <!-- LINE AREA CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Line Area Chart" code-toggler>
                    <!-- <vue-apex-charts type="area" height="350" :options="lineAreaChartSplineOption.chartOptions" :series="lineAreaChartSpline.series"></vue-apex-charts> -->
                    <!-- <p>{{apexChartLineArea}}</p> -->
                    <vue-apex-charts type="area" height="350" :options="lineAreaChartSplineOption.chartOptions" :series="apexChartLineArea"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.lineAreaChartSplineCode }}
                    </template>
                </vx-card>
            </div>

            <!-- COLUMN CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Column Chart" code-toggler>
                    <vue-apex-charts type="bar" height="350" :options="apexChatData.columnChart.chartOptions" :series="apexChatData.columnChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.columnChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- Bar CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Bar Chart" code-toggler>
                    <vue-apex-charts type="bar" height="350" :options="apexChatData.barChart.chartOptions" :series="apexChatData.barChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.barChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- MIXED CHART -->
            <div class="vx-col w-full mb-base">
                <vx-card title="Mixed Chart" code-toggler>
                    <vue-apex-charts type="line" height="350" :options="apexChatData.mixedChart.chartOptions" :series="apexChatData.mixedChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.mixedChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- CANDLESTICK CHART -->
            <div class="vx-col w-full mb-base">
                <vx-card title="Candlestick Chart" code-toggler>
                    <vue-apex-charts type="candlestick" height="350" :options="apexChatData.candlestickChart.chartOptions" :series="apexChatData.candlestickChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.candlestickChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- BUBBLE CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="3D Bubble Chart" code-toggler>
                    <vue-apex-charts type="bubble" height="350" :options="apexChatData.bubbleChart.chartOptions" :series="apexChatData.bubbleChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.bubbleChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- SCATTER CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Scatter Chart" code-toggler>
                    <vue-apex-charts type="scatter" height="350" :options="apexChatData.scatterChart.chartOptions" :series="apexChatData.scatterChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.scatterChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- HEAT MAP CHART -->
            <div class="vx-col w-full mb-base">
                <vx-card title="Heat Map Chart" code-toggler>
                    <vue-apex-charts type="heatmap" height="350" :options="apexChatData.heatMapChart.chartOptions" :series="apexChatData.heatMapChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.heatMapChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- PIE CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Pie Chart" code-toggler>
                    <vue-apex-charts type="pie" height="350" :options="apexChatData.pieChart.chartOptions" :series="apexChatData.pieChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.pieChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- DONUT CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Donut Chart" code-toggler>
                    <vue-apex-charts type="donut" height="350" :options="apexChatData.donutChart.chartOptions" :series="apexChatData.donutChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.donutChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- RADIAL BAR CHART -->
            <div class="vx-col md:w-1/2 w-full mb-base">
                <vx-card title="Radial Bar Chart" code-toggler>
                    <vue-apex-charts type="radialBar" height="350" :options="apexChatData.radialBarChart.chartOptions" :series="apexChatData.radialBarChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.radialBarChartCode }}
                    </template>
                </vx-card>
            </div>

            <!-- RADAR CHART -->
            <div class="vx-col md:w-1/2 w-full">
                <vx-card title="Radar Chart" code-toggler>
                    <vue-apex-charts type="radar" height="350" :options="apexChatData.radarChart.chartOptions" :series="apexChatData.radarChart.series"></vue-apex-charts>
                    <template slot="codeContainer">
{{ apexChatData.radarChartCode }}
                    </template>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData.js'
import { db } from '@/firebase/firebaseConfig'

const themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E']

export default {
  data () {
    return {
      apexChatData,
      apexChartLineArea: {},
      lineAreaChartSpline: {
        series: [
          {
            name: 'series1',
            data: [131, 140, 128, 151, 142, 109, 100]
          },
          {
            name: 'series2',
            data: [211, 232, 145, 232, 134, 252, 141]
          }
        ]
      },
      lineAreaChartSplineOption:{
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: themeColors,
          xaxis: {
            type: 'datetime',
            // categories: ['2019-09-19T00:00:00', '2019-09-19T01:30:00', '2019-09-19T02:30:00', '2019-09-19T03:30:00', '2019-09-19T04:30:00', '2019-09-19T05:30:00', '2019-09-20T06:30:00']
            categories: ['2019-09-10', '2019-09-11', '2019-09-12', '2019-09-13T10:00:00', '2019-09-14T00:00:00', '2019-09-15T00:00:00', '2019-09-16T00:00:00']
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            }

          }
        }
      }
    }
  },
  components: {
    VueApexCharts
  },
  firestore () {
    return {
      apexChartLineArea: db.collection('apexChartLineArea')
    //   subscribersGainedTitle: db.collection('subsgained').doc('title'),
    //   subscribersGainedCount: db.collection('subsgained').doc('count'),
    }
  }
}
</script>
